<template>
  <div class="mb-5" v-if="this.assessment">
    <div class="mt-3 flex items-center justify-between">
      <div>
        <h1 class="text-xl text-grey-light font-medium font-headers mb-3">
          <span v-show="!this.inProgress"> Team Results / </span>
          <span class="font-bold text-grey-dark-3 pt-1"
            >Assessment Overview</span
          >
          <span
            v-show="this.inProgress"
            class="border border-blue ml-2 rounded text-sm-2 p-0.5 text-blue align-middle"
          >
            In Progress
          </span>
        </h1>
      </div>
      <div v-show="!this.inProgress">
        <Button
          text="Download"
          type="primary"
          size="medium"
          :iconLeft="downloadIcon"
          @click.native="downloadPdf"
          :disabled="!this.assessment.renderResults"
        />
      </div>
    </div>
    <div class="mt-3 flex space-x-2" v-if="!this.inProgress">
      <div class="w-1/4">
        <Select
          :options="this.assessmentsOptions"
          :onSelect="setAssessmentValue"
          :value="selectedAssessment"
          label="Assessment Name"
          :preselectFirst="false"
          objects
        />
      </div>
      <div v-if="!assessment.restrictManagersResults" class="w-1/4">
        <Select
          :options="this.teamsOptions"
          :onSelect="setTeamValue"
          :value="selectedTeam"
          :preselectFirst="false"
          label="Team"
          objects
        />
      </div>
      <div v-if="isIndividual" class="w-1/4">
        <Select
          label="Job Role"
          :onSelect="setJobRole"
          :options="jobOptions"
          :value="selectedJobrole"
          :preselectFirst="false"
          objects
        />
      </div>
      <div v-if="!assessment.restrictManagersResults" class="w-1/4">
        <Select
          label="Employee Name"
          :options="employeesOptions"
          :value="selectedMember"
          :onSelect="setEmployee"
          objects
        />
      </div>
    </div>
    <div class="mt-2 space-x-2 flex" v-if="this.inProgress">
      <SmallInfoCard
        v-for="(stat, index) in surveyStats"
        :data="stat"
        :icon="stat.icon"
        :key="index"
      />
    </div>
    <div class="mt-2 bg-white">
      <UserCard :member="member" v-if="member" />
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded" v-if="assessment">
      <div class="flex flex-row space-x-5 xl:space-x-11">
        <div>
          <p class="text-sm-2 text-grey-light">Assessment Name</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4 max-w-300">
            {{ assessment.title }}
          </p>
        </div>
        <div>
          <p class="text-sm-2 text-grey-light">Launch Date</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4">
            {{ assessment.launchedOn }}
          </p>
        </div>
        <div>
          <p class="text-sm-2 text-grey-light">Close Date</p>
          <p class="text-grey-dark-1 text-md-2 font-medium mt-0.4">
            {{ assessment.dueOn }}
          </p>
        </div>
      </div>
      <div class="flex flex-row mt-3">
        <div>
          <p class="text-sm-2 text-grey-light">About Your Results</p>
          <p class="text-grey-dark-1 text-md mt-1 w-1/2 pr-4">
            The Assessment is based on a comprehensive competency-based
            capability model comprised of core competencies and sub-competencies
            for high-performing practitioners worldwide.
          </p>
          <div class="text-grey-dark-1 text-md mt-4 w-1/2 pr-4">
            <p>Competency Level</p>
            <div class="mt-1 flex flex-wrap">
              <p
                v-for="(item) in competencyLevels"
                :key="item.score"
                class="mr-3"
              >
                <span class="font-bold">{{ item.score }}.0</span>
                = {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 space-x-2 flex" v-if="!this.inProgress && !this.member">
      <SmallInfoCard
        v-for="(stat, index) in surveyStats"
        :data="stat"
        :icon="stat.icon"
        :key="index"
      />
    </div>
    <TeamEngagement v-show="inProgress" />
    <div
      class="bg-white shadow mt-3 p-3 rounded mt-2"
      v-if="competencyResults && this.assessment.renderResults"
    >
      <h2 class="text-grey-light text-mdl font-bold font-headers mt-1">
        {{
          inProgress
            ? "Indicative Results and Target Scores for Competencies"
            : "Results and Target Scores for Competencies"
        }}
      </h2>
      <div class="mt-2">
        <LineBarChart
          v-if="competencyResults.barData.data.length !== 0"
          :categories="competencyResults.categories"
          :barData="competencyResults.barData"
          :lineData="competencyResults.lineData"
          :plotSpace="60"
          height="450"
          :yAxisMaxValue="yAxisMaxValue"
          :numDivLines="numDivLines"
          id="competency-chart"
        />
        <p v-else class="ml-2 text-mdh text-grey-dark-1">No results yet.</p>
      </div>
    </div>
    <div class="bg-white shadow mt-2 p-3 rounded" id="compDelta">
      <DeltaTable
        v-if="
          competencyResults &&
            this.assessment.renderResults &&
            competencyResults.tableData.length !== 0
        "
        :title="
          inProgress
            ? 'Indicative Detailed Results by Core Competency'
            : 'Overall Results by Core Competency'
        "
        type="competency"
        :tableData="competencyResults.tableData"
      />
      <div v-else>
        <p class="text-grey-light text-mdl font-bold font-headers mt-1 mb-2">
          Indicative Team Detailed Results by Core Competency
        </p>
        <p class="ml-2 text-mdh text-grey-dark-1 mb-2.5">
          The 16 core competencies from largest to smallest
          <span class="font-bold">delta</span> (Actual Score-Target Score).
        </p>
        <p class="ml-2 text-mdh text-grey-dark-1">No results yet.</p>
      </div>
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded mt-2">
      <h2 class="text-grey-light text-mdl font-bold font-headers mt-1 mb-2">
        {{
          inProgress
            ? "Indicative Team Results and Target Scores for Sub-competency"
            : "Results and Target Scores for Sub-competencies"
        }}
      </h2>
      <div
        class="mt-2"
        v-if="
          subcompetencyResults &&
            this.assessment.renderResults &&
            subcompetencyResults.barData.data.length !== 0
        "
      >
        <LineBarChart
          :categories="subcompetencyResults.categories"
          :barData="subcompetencyResults.barData"
          :lineData="subcompetencyResults.lineData"
          :plotSpace="15"
          height="450"
          :yAxisMaxValue="yAxisMaxValue"
          :numDivLines="numDivLines"
          :hideLegend="true"
          :key="key"
          id="subcompetency-chart"
        />
        <StaticLegend
          class="mt-3 ml-2"
          :withManagerial="true"
          :legend="subcompetencyLegend"
          :withTeam="selectedEmployee && selectedEmployee.roleGroup !== -1"
        />
      </div>
      <p v-else class="ml-2 text-mdh text-grey-dark-1">No results yet.</p>
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded mt-2" id="subcompDelta">
      <DeltaTable
        type="subcompetency"
        v-if="
          subcompetencyResults &&
            this.assessment.renderResults &&
            subcompetencyResults.tableData.length !== 0
        "
        :title="
          inProgress
            ? 'Indicative Detailed Company Results by Sub-Competency'
            : 'Overall Results by Sub-competency'
        "
        :tableData="subcompetencyResults.tableData"
      />
      <div v-else>
        <p class="text-grey-light text-mdl font-bold font-headers mt-1 mb-2">
          Indicative Detailed Team Results by Sub-Competency
        </p>
        <p class="ml-2 text-mdh text-grey-dark-1">No results yet.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DeltaTable from "@/components/DeltaTable/DeltaTable";
import UserCard from "@/components/miscellaneous/UserCard";
import Stats from "../components/Stats";
import { employees } from "../mock/employees";
import StaticLegend from "@/components/Charts/StaticLegend.vue";
import TeamEngagement from "../components/TeamEngagement/index";
import downloadIcon from "@/assets/img/icons/download.svg";
import FusionCharts from "fusioncharts";

export default {
  name: "TeamAssessmentResult",
  components: {
    DeltaTable,
    Stats,
    UserCard,
    StaticLegend,
    TeamEngagement,
  },
  data: () => ({
    employees,
    downloadIcon,
    renderResults: true,
    compData: {},
    subCompData: {},
    userLine: [],
    selectedEmployee: null,
    selectedAssessment: null,
    selectedTeam: { label: "All teams", id: -1 },
    selectedMember: { label: "All employees", id: 0 },
    selectedJobrole: { label: "All job roles", id: 0 },
    key: Math.random(),
    roles: [
      {
        label: "All roles",
        id: -1,
      },
      {
        label: "Procurement Specialist",
        id: 0,
      },
      {
        label: "Analyst",
        id: 1,
      },
      {
        label: "Senior Buyer",
        id: 2,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      getMemberDetails: "teams/getMemberDetails",
    }),
    inProgress() {
      return this.assessment.inProgress;
    },
    ...mapState({
      assessments: (state) => state.teamResults.assessments,
      subcompetencyLegend: (state) => state.teamResults.subcompetencyLegend,
      teams: (state) => state.teamResults.teams,
      surveyStats: (state) => state.teamResults.surveyStats,
      assessment: (state) => state.teamResults.assessment,
      competencyResults: (state) => state.teamResults.competencyResults,
      subcompetencyResults: (state) => state.teamResults.subcompetencyResults,
      jobRoles: (state) => state.teamResults.jobRoles,
      members: (state) => state.teams.surveyMembers,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    isIndividual() {
      return this.assessment?.surveyType === 0;
    },
    isOrganizational() {
      return this.assessment?.surveyType === 1;
    },
    yAxisMaxValue() {
      return this.assessment.competencyLevels.length - 1;
    },
    numDivLines() {
      return (this.assessment.competencyLevels.length - 1) * 2 - 1;
    },
    competencyLevels() {
      return this.assessment.competencyLevels;
    },
    member() {
      if (this.selectedMember.id !== 0)
        return this.getMemberDetails(this.selectedMember.id);
    },
    assessmentsOptions() {
      return this.assessments.map((el) => ({
        label: el.name,
        id: el.id,
      }));
    },
    teamsOptions() {
      return [
        { label: "All teams", id: -1 },
        ...this.teams.map((el) => ({
          label: el.name,
          id: el.id,
        })),
      ];
    },
    jobOptions() {
      return [
        { label: "All job roles", id: 0 },
        ...this.jobRoles.map((el) => ({
          label: el.name,
          id: el.id,
        })),
      ];
    },
    isClosed() {
      if (+new Date(this.assessment.dueOn) < +new Date()) return true;
      return false;
    },
    employeesOptions() {
      return [
        { label: "All employees", id: 0 },
        ...this.members.map((el) => ({
          label: el.firstName + " " + el.lastName,
          id: el.id,
        })),
      ];
    },
    filteredStats() {
      return this.assessment.stats.find((el) => el.group === this.selectedRole);
    },
    renderUserLine() {
      if (this.selectedEmployee && this.selectedEmployee.roleGroup !== -1)
        return userLine;
      return null;
    },
    renderUserCompLine() {
      if (this.selectedEmployee && this.selectedEmployee.roleGroup !== -1)
        return this.compData.lineData3;
      return null;
    },
  },
  watch: {
    $route(_, from) {
      if (from.name === "TeamAssessmentResult") {
        this.init();
      } else {
        this.fetchResults();
      }
    },
    selectedAssessment(newVal, oldVal) {
      if (
        oldVal &&
        oldVal.id !== newVal.id &&
        newVal.id !== this.$route.params.id
      ) {
        this.$router.push({
          name: "TeamAssessmentResult",
          params: { id: val.id, teamId: this.$route.params.teamId },
        });
      }
    },
  },
  methods: {
    ...mapActions({
      resetTeamResults: "teamResults/resetTeamResults",
      getSurveyStats: "teamResults/getSurveyStats",
      getTeamProgress: "teamResults/getTeamProgress",
      getAssessment: "teamResults/getAssessment",
      getAssessmentCompetencyResults:
        "teamResults/getAssessmentCompetencyResults",
      getAssessmentSubcompetencyResults:
        "teamResults/getAssessmentSubcompetencyResults",
      getTeamAssessments: "teamResults/getTeamAssessments",
      getJobRoles: "teamResults/getJobRoles",
      getTeamsForAssessment: "teamResults/getTeamsForAssessment",
      getMembersOfSurvey: "teams/getMembersOfSurvey",
      download: "teamResults/downloadTeamPdf",
    }),
    exportChart(chartName) {
      const chart = FusionCharts(chartName);
      var svg = chart.getSVGString();
      svg = svg.replace(/&nbsp;/g, "&#160;");
      return window.btoa(unescape(encodeURIComponent(svg)));
    },
    downloadPdf() {
      const competencyImg = this.exportChart("competency-chart");
      const subcompetencyImg = this.exportChart("subcompetency-chart");
      this.download({
        id: this.$route.params.id,
        teamId: parseInt(this.$route.params.teamId, 10),
        jobId: this.selectedJobrole.id,
        userId: this.selectedMember.id,
        competencyImg,
        subcompetencyImg,
      });
    },
    fetchResults() {
      if (this.assessment.renderResults) {
        this.getAssessmentCompetencyResults({
          id: this.$route.params.id,
          teamId: this.$route.params.teamId,
          jobId: this.selectedJobrole.id,
          userId: this.selectedMember.id,
        });
        this.getAssessmentSubcompetencyResults({
          id: this.$route.params.id,
          teamId: this.$route.params.teamId,
          jobId: this.selectedJobrole.id,
          userId: this.selectedMember.id,
        });
      }
    },
    async init() {
      await this.getAssessment({ id: this.$route.params.id });
      await this.getTeamAssessments();
      await this.getTeamProgress({
        surveyId: this.$route.params.id,
        teamId: this.$route.params.teamId,
      });
      await this.getTeamsForAssessment({ id: this.$route.params.id });
      await this.getMembersOfSurvey({
        surveyId: this.$route.params.id,
        teamId: this.$route.params.teamId,
        jobRoleId: this.selectedJobrole.id,
      });
      this.selectedAssessment = {
        id: this.assessment.id,
        label: this.assessment.title,
      };
      this.selectedTeam = this.teamsOptions.find(
        (el) => el.id === parseInt(this.$route.params.teamId, 10)
      );
      await this.getJobRoles({
        surveyId: this.$route.params.id,
        teamId: this.$route.params.teamId,
      });
      await this.getSurveyStats({
        surveyId: this.selectedAssessment.id,
        teamId: this.$route.params.teamId,
        jobRoleId: this.selectedJobrole.id,
      });
      this.fetchResults();
    },
    setAssessmentValue(val) {
      this.selectedAssessment = val;
      if (
        !this.teamsOptions.find((el) => +el.id === +this.$route.params.teamId)
      ) {
        this.setTeamValue(this.teamsOptions[0]);
      }
      this.setJobRole({ label: "All job roles", id: 0 });
      this.$router.push({
        name: "TeamAssessmentResult",
        params: { id: val.id, teamId: this.$route.params.teamId },
      });
    },
    setTeamValue(val) {
      this.selectedTeam = val;
      this.$router.push({
        name: "TeamAssessmentResult",
        params: { id: this.assessment.id, teamId: val.id },
      });
      this.setJobRole({ label: "All job roles", id: 0 });
    },
    async setJobRole(val, member) {
      this.selectedJobrole = val;
      if (!member) {
        this.setEmployee({ label: "All employees", id: 0 });
      } else {
        this.setEmployee(member);
      }
      await this.getMembersOfSurvey({
        surveyId: this.$route.params.id,
        teamId: this.$route.params.teamId,
        jobRoleId: this.selectedJobrole.id,
      });
      await this.getSurveyStats({
        surveyId: this.$route.params.id,
        teamId: this.$route.params.teamId,
        jobRoleId: this.selectedJobrole.id,
      });
      this.fetchResults();
    },
    async setEmployee(val) {
      this.selectedMember = val;
      if (this.selectedJobrole.id === 0 && val.id !== 0) {
        const role = {
          label: this.member.jobRoleName,
          id: this.member.jobRoleId,
        };
        const member = {
          label: this.member.firstName + " " + this.member.lastName,
          id: this.member.id,
        };
        await this.setJobRole(role, member);
      }
      this.fetchResults();
    },
  },
  async created() {
    if (this.$route.params.id === "undefined") {
      return await this.$router.push("/team-results/empty");
    }
    await this.init();
  },
  async mounted() {
    await this.resetTeamResults();
    await this.getSurveyStats({
      surveyId: this.$route.params.id,
      teamId: this.$route.params.teamId,
      jobRoleId: this.selectedJobrole.id,
    });
    await this.getTeamProgress({
      surveyId: this.$route.params.id,
      teamId: this.$route.params.teamId,
    });
  },
};
</script>

<style></style>
